@import './src/scss/variables';
@import './src/scss/game';

$boxColor: #ffb8b8;
$gridColor: grey;

.app__container {
  display: flex;
  justify-content: center;

  #next {
    display: flex;
    padding-left: 20px;
    width: 100px;
    flex-direction: column;

    &.version-2, &.version-3 {
      flex-direction: column-reverse;
    }

    .row {
      display: flex;
      flex-direction: row;
    }
  }

  #tetro {
    display: flex;
    flex-direction: column;
    background-color: $gridColor;
    border: 1px solid $gridColor;

    &.version-2, &.version-3 {
      flex-direction: column-reverse;
    }

    &.version-3 {
      .row {
        flex-direction: row-reverse;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
    }
  }

  &.show-shadow {
    .brick:after {
      //box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5) inset;
    }

    .shadow {
      //background-color: black;
      opacity: 0.5 !important;
    }
  }

  &.show-grid {
    #tetro {
      gap: 1px;
      background-color: $gridColor;
      border: 1px solid $gridColor;

      .row {
        gap: 1px;
      }
    }

    #next {
      .row {
        margin-top: -1px;
        .brick-box {
          background: transparent;
          margin-right: -1px;
        }
        .brick {
          border: 1px solid black;
          margin-right: -1px;
        }
      }
    }
  }
}


.row {
  height: 20px;
}

.brick-box,
.brick,
.cell,
.shadow {
  display: inline-block;
  //margin-left: 3px;
  height: 20px;
  width: 20px;
  position: relative;

  &:after {
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}

.brick-box {
  //background-color: $boxColor;
  background-color: lightgrey;
  //background-color: burlywood;
  //background-color: lightgrey;
}

.brick {
  background-color: #baefba;

  &-color-1 {
    background-color: blue;
  }

  &-color-2 {
    background-color: red;
  }

  &-color-3 {
    background-color: yellow;
  }

  &-color-4 {
    background-color: purple;
  }

  &-color-5 {
    background-color: #57ffd3;
  }

  &-color-6 {
    background-color: white;
  }

  &-color-7 {
    background-color: #ff0000;
  }

  &-color-10 {
    //background-color: $boxColor;

    &:after {
      border: 0 !important;
    }
  }
}

.cell,
  //.brick-box,
.shadow {
  background-color: lightgrey;
}